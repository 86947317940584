import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Accueil/Home';
import Contact from './pages/Contact/Contact';
import Error404 from './pages/Error404';
import AOS from 'aos';
import "aos/dist/aos.css";
import './ressources/styles/scrollbar.css';
import ScrollToTop from './components/ScrollToTop';
import QuiSommesNous from './pages/QuiSommesNous/QuiSommesNous';
import NotreOffre from './pages/NotreOffre/NotreOffre';
import Carrières from './pages/Carrieres/Carrieres';
import ValidationMessage from './pages/Contact/ValidationMessage';
import MentionsLegales from './pages/MentionsLegales/MentionsLegales';
import SpamMessage from './pages/Contact/Spam';
import LaVieChezOrmaes from './pages/LaVieChezOrmaes/LaVieChezOrmaes';
import Teapot from './pages/Teapot/Teapot';
import { SnackbarProvider } from "notistack";
import ReactGA from 'react-ga4';

export default function App() {
    const location = useLocation();

    useEffect(() => {
        AOS.init();
    }, []);

    useEffect(() => {
        // Send a pageview event to Google Analytics on route change
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return (
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Header/>
            <main>
                <ScrollToTop/>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="qui-sommes-nous" element={<QuiSommesNous/>}/>
                    <Route path="notre-offre" element={<NotreOffre/>}/>
                    <Route path="carrieres" element={<Carrières/>}/>
                    <Route path="contact" element={<Contact/>}/>
                    <Route path="mentions-legales" element={<MentionsLegales/>}/>
                    <Route path="envoie-reussi" element={<ValidationMessage/>}/>
                    <Route path="erreur-envoi" element={<SpamMessage/>}/>
                    <Route path="teapot" element={<Teapot/>}/>
                    <Route path="la-vie-chez-ormaes" element={<LaVieChezOrmaes/>}/>
                    <Route path="*" element={<Error404/>}/>
                </Routes>
            </main>
            <Footer/>
        </SnackbarProvider>
    );
}
